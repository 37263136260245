
import Vue from "vue"

export default Vue.extend({
    name: "FormStepHeader",
	props: {
		msg: String,
		stepNumber: String,
	},
})
